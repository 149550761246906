import React, { Component } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import imgHero from "../images/gfx/hero-article.jpg"
import imgMobileHero from "../images/gfx/mobile-hero-2.jpg"

import imgNokia105 from "../images/bricket-school-bus-ticket-nokia-105.jpg"
import imgNokia3310 from "../images/bricket-school-bus-ticket-nokia-3310.jpg"
import imgMobileTicket from "../images/mobile-ticket-for-school-transport.jpg"
import imgSaveTime from "../images/save-time-money-using-shuttleid.jpg"
import imgProfileChrisBell from "../images/chris-bell-profile-square.jpg"
import "./index.css";
import "./case-study.css";
import "./article.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Contact from "../components/contact"
import Footer from "../components/footer"
import Nav from "../components/nav"

class BlogPage extends Component {

  componentDidMount(){
    AOS.init({
      duration : 2000
    });
  }

  componentDidUpdate() {
    AOS.refresh();
  }

  render() {
    return (
      <Layout>
        <SEO title="Brickets Please! School bus tickets on brick phones" />
        <Nav activeTab="blog" />
        <div>
          <div className="case-study">

              <div className="hero-article" style={{ backgroundImage: `url(${imgHero})`, backgroundColor: '#063a70', backgroundSize: '100% auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'left center' }}>
                <h1 className="blog-headline">BRICKETS PLEASE!<br />School bus tickets on brick phones</h1>
              </div>

              <div className="mobile-hero-article" style={{ backgroundImage: `url(${imgMobileHero})`, backgroundColor: '#084e96', backgroundSize: '100% auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'center top' }}>
                <h1 className="blog-headline">BRICKETS PLEASE!<br />School bus tickets on brick phones</h1>
              </div>

              <div>

                <div className="article">
                  <div className='article-intro article-inner'>
                    <p>Addressing an emerging trend of brick phone adoption by parents of school children, ShuttleID are pleased to share the launch of <strong>Brickets</strong>: A new style of digital bus ticket designed to work on virtually any type of “brick” phone.</p>
                    <p><img src={imgNokia3310} alt="" /></p>
                  </div>
                  <div className='article-inner'>
                    <h2>Smartphone ban</h2>
                    <p>Following concerns about the impact of smartphones on children’s education and behaviour, calls have been growing to ban smartphones, not only inside the classroom but beyond. Earlier this year, the UK's Commons education committee recommended that the government <a className="link" href="https://www.bbc.co.uk/news/articles/cjmm0zgx9zno" target="_blank">considers a total ban on smartphones for under-16s</a>.</p>
                    <p>With smartphone bans already in place in other countries, UK schools and parents are taking matters into their own hands. One parent pact, <a className="link" href="https://www.theguardian.com/technology/2024/sep/26/uk-parents-join-pact-to-withhold-smartphones-from-children-until-they-are-at-least-14" target="_blank">signed by parents across 6,537 schools</a>, aims to delay smartphone adoption amongst school children until year 9.</p>
                    <p><a className="link" href="https://www.bbc.co.uk/news/uk-england-beds-bucks-herts-69044426" target="_blank">Schools have advocated</a> and <a className="link" href="https://www.bbc.co.uk/news/articles/c06k33j1dzlo">provisioned “brick” style phones</a> without access to apps or internet for younger students.</p>

                    <h2>How Brickets work</h2>
                    <p>Embracing this change, ShuttleID has developed a new style of digital bus ticket that works on virtually any type of “brick” phone, helping transport operators play a key role in a wider societal shift.</p>
                    <p><img src={imgNokia105} alt="" /></p>
                    <p>The technology is driven by a QR code that displays on the brick phone, which is then scanned by the driver’s device. ShuttleID developed and identified up to six different ways to transfer the digital ticket onto a brick phone, working with the hardware constraints:</p>
                    <ol>
                      <li><strong>SMS with ticket link</strong>: For brick phones that include a basic or limited web browser (e.g. Opera mini), parents can hit a button to send a text containing a link designed specifically for brick phones. Additionally, the ticket link can be saved, which means it can work without data (offline) after the initial load. Performance optimisations ensure the ticket loads quickly even for brick phones on 2G networks.</li>
                      <li><strong>MMS including ticket</strong>: For brick phones that include MMS (picture messaging) support, parents can hit a button to send a specially crafted message containing a ticket barcode. The barcode can either be accessed directly from messages, or as a saved photo.</li>
                      <li><strong>Photo</strong>: For brick phones with a camera, users can take a photo of the barcode located on the parent's smartphone or computer.</li>
                      <li><strong>Memory card</strong>: For devices with an external memory card, parents can transfer the barcode from their computer directly onto the memory card, which can then be accessed by the brick phone.</li>
                      <li><strong>USB</strong>: For devices with accessible internal memory, parents can connect the brick phone directly to a computer via USB, and then save the barcode directly onto the device.</li>
                      <li><strong>Bluetooth</strong>: Brick phones with Bluetooth support can receive the barcode sent directly from the parent's device.</li>
                    </ol>

                    <p>This inclusive change means that passengers can now use bus tickets that are printed, on a smartphone or on a brick phone.</p>
                    <p style={{ background: '#084e96', color: '#fff', padding: 15 }}><span style={{ display: 'block', fontWeight: 'bold' }}>Can ShuttleID help me?</span>Yes! The benefits of ShuttleID not only makes running your school bus easier to manage but importantly it makes your users happy. Our technology is designed to be simple, affordable and keeps you in full control. Get in touch today on 0333 344 9868 for more information or book your <Link style={{ fontWeight: 'bold', color: 'white' }} to="/book-demo/">free online demo</Link>.</p>

                    <div className='author' style={{ border: '1px solid #d5e6f5', background: '#f3f9ff', marginBottom: '10px' }}>
                      <div className='author__img'>
                        <img src={imgProfileChrisBell} alt="Chris Bell" />
                      </div>
                      <div className='author__quote' style={{  }}>
                        <p style={{ marginBottom: 10 }}><strong>About the author</strong></p>
                        <p style={{ marginBottom: 10 }}>Chris Bell is a director at ShuttleID; the affordable, digital bus pass and ticketing system that keeps you in control.</p>
                        <p style={{ marginBottom: 10 }}>During a 15 year career in technology, Chris worked on many websites and systems for coach companies and saw the issues of managing home-to-school first hand, leading to the launch of ShuttleID in 2019.</p>
                        <p style={{ margin: 0 }}>Email: <a className="link" href="mailto:info@shuttleid.uk">info@shuttleid.uk</a> | Phone: 0333 344 9868</p>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <Contact />
            </div>
        </div>
        <Footer />
      </Layout>
    );
  }
};

export default BlogPage